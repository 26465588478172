import './single-image.scss';

import { sendTracking } from '../../js/tracking';

class SingleImageSlider {
    constructor (element, options) {
        const defaults = {
            trackingAttr: 'data-trackingcategory'
        };

        this.settings = Object.assign({}, defaults, options);

        this.$slider = element;
        this.trackingCategory = this.$slider.getAttribute(this.settings.trackingAttr);
        this.currentIndex = 0;
    }

    initialize () {
        const handleSlider = (Slider) => {
            let clicked = false;
            this.teaserSlider = new Slider(this.$slider, {
                effect: 'fade',
                breakpoints: null,
                speed: 500,
                indicators: true,
                prevnext: true,
                slidesPerView: 1,
                initAttr: 'data-single-image-slider',
                onSlideChange: ($slider, slider, $currentSlide, currentIndex) => {
                    if (this.trackingCategory !== null) {
                        let direction;
                        if (currentIndex > this.currentIndex) {
                            direction = 'forth';
                        } else {
                            direction = 'back';
                        }

                        if (clicked === false) {
                            sendTracking({
                                category: this.trackingCategory,
                                action: 'swipe',
                                label: 'slide_' + direction + '_' + (currentIndex + 1)
                            });
                        }

                        this.currentIndex = currentIndex;
                        clicked = false;
                    }
                },
                onClickPrev: (currentIndex) => {
                    clicked = true;

                    if (this.trackingCategory !== null) {
                        sendTracking({
                            category: this.trackingCategory,
                            action: 'click',
                            label: 'btn_back_' + (currentIndex + 1)
                        });
                    }
                },
                onClickNext: (currentIndex) => {
                    clicked = true;

                    if (this.trackingCategory !== null) {
                        sendTracking({
                            category: this.trackingCategory,
                            action: 'click',
                            label: 'btn_forth_' + (currentIndex + 1)
                        });
                    }
                }
            });
        };

        import('../../components/slider/slider').then(({ Slider }) => {
            handleSlider(Slider);
        });
    }
}

export { SingleImageSlider };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $singleImageSliders = $context.querySelectorAll('[data-single-image-slider="slider"]');

        if ($singleImageSliders.length > 0) {
            for (let i = 0; i < $singleImageSliders.length; i++) {
                const $singleImageSlider = new SingleImageSlider($singleImageSliders[i]);
                $singleImageSlider.initialize();
            }
        }
    }
});
